<template>
  <div class="container">
    <el-card>
      <div class="search">
        <el-button type="primary" @click="openAddDialog">添加企业</el-button>
      </div>
      <div class="main">
        <el-table :data="tableData" style="width: 100%" border stripe>
          <el-table-column prop="name" label="企业名称" width="180">
          </el-table-column>
          <el-table-column prop="address" label="企业地址" width="180">
          </el-table-column>
          <el-table-column prop="business" label="企业性质"> </el-table-column>
          <el-table-column prop="establishment" label="企业成立日期">
          </el-table-column>
          <el-table-column prop="synopsis" label="企业简介"> </el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <el-button type="primary" @click="editOpenDialog(scope.row)">修改</el-button>

              <!-- <el-button type="primary" @click="openAddDialog">添加企业</el-button>
               -->
              <!-- <el-button type="primary" @click="openAddProjectDialog(scope.row)"
                >添加项目</el-button
              >
              <el-button type="danger" @click="deleteEnterprise(scope.row)"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 添加企业对话框 -->
      <el-dialog title="添加企业" :visible.sync="addEnterpriseDialogVisible" width="40%"
        @close="closeEnterpriseDialogVisible">
        <el-form ref="addFormData" :model="addFormData" label-width="100px">
          <el-form-item label="企业名称" prop="name">
            <el-input v-model="addFormData.name"></el-input>
          </el-form-item>
          <el-form-item label="企业地址" prop="address">
            <el-input v-model="addFormData.address"></el-input>
          </el-form-item>
          <el-form-item label="企业性质" prop="business">
            <el-input v-model="addFormData.business"></el-input>
          </el-form-item>
          <el-form-item label="企业成立日期" prop="establishment">
            <el-date-picker v-model="addFormData.establishment" type="date" placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="企业简介" prop="synopsis">
            <el-input v-model="addFormData.synopsis"></el-input>
          </el-form-item>
          <div v-if="rank == '0'">
            <el-form-item label="公司类型">
              <el-radio-group v-model="addFormData.dealer">
                <el-radio label="0">公司</el-radio>
                <el-radio label="1">经销商</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="经销商列表" v-if="addFormData.dealer == '0'">
              <el-select v-model="addFormData.top_boss" clearable placeholder="请选择">
                <el-option v-for="item in data_list" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addEnterpriseDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addEnterprise">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 添加项目对话框 -->
      <el-dialog title="添加项目" :visible.sync="addDialogVisible" width="40%" @close="closeAddDialog">
        <el-form ref="addProjectFormData" :model="addProjectFormData" label-width="100px"
          :rules="addProjectFormDataRules">
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="addProjectFormData.name"></el-input>
          </el-form-item>
          <!-- <el-form-item label="所属公司">
          <el-select v-model="addFormData.corporation" placeholder="请选择">
            <el-option
              v-for="item in enterpriseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
          <el-form-item label="项目详情" prop="details">
            <el-input v-model="addProjectFormData.details"></el-input>
          </el-form-item>
          <el-form-item label="项目地址" prop="site">
            <el-input v-model="addProjectFormData.site"></el-input>
          </el-form-item>

          <el-form-item label="工程性质" prop="engineering">
            <el-input v-model="addProjectFormData.engineering"></el-input>
          </el-form-item>
          <el-form-item label="工程阶段" prop="angineering">
            <el-select v-model="addProjectFormData.angineering" placeholder="请选择">
              <el-option v-for="item in projectOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- <el-input v-model="addProjectFormData.angineering"></el-input> -->
          </el-form-item>
          <el-form-item label="开工时间" prop="create_time">
            <el-date-picker v-model="addProjectFormData.create_time" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="拟竣工时间" prop="update_time">
            <el-date-picker v-model="addProjectFormData.update_time" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="所在地" prop="detaileds">
            <el-cascader size="large" :options="options" v-model="addProjectFormData.detaileds" clearable />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addProject">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import {
  addFirm,
  getFirm,
  deleteFirm,
  editFirm,
  addProjectInfo,
  obtainDealers
} from "../../../request/project";
export default {
  name: "enterprise",
  data() {
    return {
      addEnterpriseDialogVisible: false, //控制添加企业对话框的显示与隐藏
      addFormData: {
        name: "",
        address: "",
        business: "",
        establishment: "",
        synopsis: "",
        dealer: '0',
        top_boss: '',
      }, //添加企业表单
      tableData: [], //展示企业信息表格
      flage: false,
      addDialogVisible: false, //控制添加项目对话框的显示与隐藏
      // dialogTitle: "添加项目", //对话框的标题
      addProjectFormData: {
        name: "",
        corporation: "",
        details: "",
        site: "",
        create_time: "",
        update_time: "",
        engineering: "",
        angineering: "",
        detaileds: "",
      }, //添加项目信息表单
      options: regionData, // 省市区三级联动
      selectedOptions: [],
      addProjectFormDataRules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        details: [{ required: true, message: "请输入名称", trigger: "change" }],
        site: [{ required: true, message: "请输入名称", trigger: "change" }],
        engineering: [
          { required: true, message: "请输入名称", trigger: "change" },
        ],
        angineering: [
          { required: true, message: "请输入名称", trigger: "change" },
        ],
        detailed: [
          { required: true, message: "请输入名称", trigger: "change" },
        ],
      },
      projectOptions: [
        {
          label: "基础施工阶段",
          value: "基础施工阶段",
        },
        {
          label: "主体施工阶段",
          value: "主体施工阶段",
        },
        {
          label: "装饰装修阶段",
          value: "装饰装修阶段",
        },
      ], //项目阶段下拉框
      data_list: [],
      rank: '',
    };
  },
  created() { },
  mounted() {
    this.rank = localStorage.getItem('rank')

    this.getEnterprise();
  },
  methods: {
    async obtainDealers() {
      const res = await obtainDealers()
      this.data_list = res.data.data
    },
    //   打开添加项目对话框
    openAddProjectDialog(row) {
      this.addDialogVisible = true;
      this.addProjectFormData.corporation = row.id;
    },
    // 添加项目
    async addProject() {
      // let value = this.addProjectFormData.detailed
      // let str = "";
      // for(let i = 0;i < value.length;i++) {
      //   str += CodeToText[value[i]]+"/";
      // }
      // str = str.substring(0, str.length - 1);
      // this.addProjectFormData.detailed = str
      // console.log(this.addProjectFormData);

      const res = await addProjectInfo(this.addProjectFormData);
      // console.log(res);
      if (res.status == 200) {
        this.addDialogVisible = false;
        this.$message({
          message: "添加成功",
          type: "success",
        });
      }
    },
    // 关闭添加项目对话框,清空表单
    closeAddDialog() {
      this.$refs.addProjectFormData.resetFields();
    },

    // 修改企业信息,打开对话框
    editOpenDialog(row) {
      this.addEnterpriseDialogVisible = true;
      this.flag = true;
      this.addFormData = JSON.parse(JSON.stringify(row));
    },
    async editEnterprise(row) {
      this.addEnterpriseDialogVisible = true;
      this.addFormData = row;

      this.getEnterprise();
    },
    // 获取所有企业
    async getEnterprise() {
      let msg = {
        unique_identification: localStorage.getItem('uid')

      }
      const res = await getFirm(msg);
      // console.log(res);
      this.tableData = res.data;
    },
    // 打开添加企业对话框
    openAddDialog() {
      this.addEnterpriseDialogVisible = true;
      if (this.rank != '0') {
        this.addFormData.top_boss = localStorage.getItem('companys')
      }
      this.obtainDealers()
    },
    // 关闭添加企业对话框,清除表单
    closeEnterpriseDialogVisible() {
      this.addFormData = {
        name: "",
        address: "",
        business: "",
        establishment: "",
        synopsis: "",
      };
      this.$refs.addFormData.resetFields();
      this.flag = false;
    },
    // 添加修改企业
    async addEnterprise() {
      if (this.flag) {
        const res = await editFirm(this.addFormData);
        console.log(res);
        if (res.data.code == 200) {
          this.addEnterpriseDialogVisible = false;
          this.getEnterprise();
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.addFormData = {
            name: "",
            address: "",
            business: "",
            establishment: "",
            synopsis: "",
            dealer: '0',
            top_boss: '',
          }
        } else {
          this.$message({
            message: res.data.mes,
            type: "error",
          });
        }
      } else {
        const res = await addFirm(this.addFormData);
        console.log(res);
        if (res.data.code == 200) {
          this.addEnterpriseDialogVisible = false;
          this.getEnterprise();
          this.$message({
            message: "添加成功1111111111111",
            type: "success",
          });
          this.addFormData = {
            name: "",
            address: "",
            business: "",
            establishment: "",
            synopsis: "",
            dealer: '0',
            top_boss: '',
          }
        } else {
          this.$message({
            message: res.data.mes,
            type: "error",
          });
        }
      }

      // console.log(res);
    },
    // 删除企业
    deleteEnterprise(row) {
      this.$confirm("是否删除此企业", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let msg = {
            id: row.id,
          };
          const res = await deleteFirm(msg);
          //  console.log(res);
          if (res.status == 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getEnterprise();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

      // console.log(row);
    },
  },
};
</script>

<style>
.main {
  margin-top: 20px;
}
</style>